import CardCall from "./CardCall";

const ListCardCall = ({ lists }) => {
  return (
    <div className="list-card_call">
      {lists.map((item) => (
        <CardCall UserList={item} />
      ))}
    </div>
  );
};

export default ListCardCall;
