import { createTheme, ThemeProvider } from "@mui/material";
import MaterialTable from "material-table";
import React from "react";
import { HeaderStyle, localizationTable, RowStyle } from "setup/services";
import { tableIcons } from "setup/TableIcons";

const TableYoucan = ({
  colCommandes,
  setCountSelected,
  brouillons,
  setSelectedOrder,
  loaded,
  countSelected,
}) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['"Segoe UI"', "sans-serif"].join(","),
      fontSize: 14,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        title={null}
        localization={localizationTable}
        options={{
          pageSize: 10,
          selection: true,
          selectionProps: (rowData) => ({
            checked: countSelected.includes(rowData.idraft_api),
          }),
          showSelectAllCheckbox: true,
          headerStyle: HeaderStyle(),
          rowStyle: RowStyle(),
        }}
        icons={tableIcons}
        columns={colCommandes}
        data={brouillons}
        onSelectionChange={(rows) => {
          setCountSelected(rows.map((row) => row.idraft_api));
          setSelectedOrder([rows[0]]);
        }}
        isLoading={loaded}
      />
    </ThemeProvider>
  );
};

export default TableYoucan;
