import CardAdmin from "./CardAdmin";
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";

const ListCardAdmin = ({
  cards,
  devise,
  optionSelected,
  setOptionSelected,
}) => {
  const [CountOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="main_cards-admin">
        {cards?.length > 0 &&
          cards.map((card, index) => (
            <CardAdmin
              optionSelected={optionSelected}
              setOptionSelected={setOptionSelected}
              color={card.color}
              CountOn={CountOn}
              card={card}
              key={index}
              type={[3, 4].includes(card.id) ? " " + devise : undefined}
            />
          ))}
      </div>
    </ScrollTrigger>
  );
};

export default ListCardAdmin;
