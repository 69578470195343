import {
  Alert,
  Box,
  Drawer,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

import logoWooCommerce from "../../assets/images/app-logo-woocommerce.png";
import logoShopify from "../../assets/images/Shopify.png";
import logoYoucan from "../../assets/images/LogoYoucan.png";
import logoLF from "../../assets/images/light.jpg";
import BodyDomaines from "./BodyDomaines";
import {
  CatchError,
  DeleteSalePlatforme,
  PutShopForSalePlatforme,
  ToastStatus,
} from "setup/services";
import { toast } from "react-toastify";
const DomainesMenu = ({
  open,
  toggleDrawer,
  items,
  defaultPropsBoutique,
  platforme,
  setDomaines,
}) => {
  const [lineSelected, setLineSelected] = useState({
    id: "",
    id_shop: "",
  });

  const initialState = {
    type: "info",
    msg: `   Note : Synchronisation des boutiques et des clients ${platforme} automatique .`,
  };
  const [alertMsg, setAlertMsg] = useState(initialState);
  const DeleteSetting = (id) => {
    if (platforme === "LightFunnels") {
      setAlertMsg({
        type: "error",
        msg: "Vous peuvez pas supprimer compte LightFunnels",
      });
      return;
    }
    DeleteSalePlatforme(id, platforme)
      .then(() => {
        toast.success(`${platforme} boutique a été supprimer`, {
          ...ToastStatus,
        });
        const newData = items.filter((domaine) => domaine.id !== id);
        setDomaines(newData);
      })
      .catch(() => {
        CatchError();
      });
  };
  const UpdateSetting = () => {
    if (lineSelected.id === "" || lineSelected.id_shop === "") {
      setAlertMsg({
        type: "error",
        msg: "Choisissez la boutique que vous souhaitez synchroniser avec la boutique actuelle.",
      });
      return;
    } else {
      setAlertMsg(initialState);
    }
    PutShopForSalePlatforme(lineSelected.id, lineSelected.id_shop)
      .then(() => {
        toast.success(`${platforme} boutique a été modifiée`, {
          ...ToastStatus,
        });
      })
      .catch(() => {
        CatchError();
      });
  };
  const getLogoForPlatforme = () => {
    let logo = "";
    switch (platforme) {
      case "Shopify":
        logo = logoShopify;
        break;
      case "Youcan":
        logo = logoYoucan;
        break;
      case "LightFunnels":
        logo = logoLF;
        break;
      default:
        logo = logoWooCommerce;
    }
    return logo;
  };
  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box height={"100%"} sx={{ backgroundColor: "#ececec" }}>
        <Box
          display={"flex"}
          columnGap={1}
          alignItems="center"
          padding={3}
          sx={{ backgroundColor: "white" }}
        >
          <img
            src={getLogoForPlatforme()}
            alt="Meta Logo"
            width={80}
            height={80}
          />
          <Box fontWeight={"700"} component={Box}>
            Connecter {platforme} Store
            <Box color={"GrayText"} fontSize={12}>
              Gérer liste des domaines à partir d'ici. Toutes les domaines sont
              sécurisées.
            </Box>{" "}
          </Box>
        </Box>
        <Alert severity={alertMsg.type}>{alertMsg.msg}</Alert>
        {items?.length > 0 && (
          <TableContainer sx={{ maxWidth: "max-content" }} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Domaine {platforme}</TableCell>
                  <TableCell align="center">Boutique</TableCell>
                  <TableCell align="center">Modifier</TableCell>
                  <TableCell align="center">Supprimer</TableCell>
                </TableRow>
              </TableHead>
              <BodyDomaines
                DeleteSetting={DeleteSetting}
                UpdateSetting={UpdateSetting}
                defaultPropsBoutique={defaultPropsBoutique}
                items={items}
                setLineSelected={setLineSelected}
              />
            </Table>
          </TableContainer>
        )}
      </Box>
    </Drawer>
  );
};

export default DomainesMenu;
