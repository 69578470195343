import MaterialTable from "material-table";
import {
  Autocomplete,
  createTheme,
  TextField,
  ThemeProvider,
  Alert,
  Box,
  Paper,
  Tooltip,
  IconButton,
  Badge,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ImDownload3 } from "react-icons/im";
import { useContext, useEffect, useState } from "react";
import Title from "components/Title";
import { read, utils } from "xlsx";
import {
  ToastStatus,
  getAllShops,
  localizationTable,
  DeleteBrouillon,
  getAllCustomers,
  getAllProvince,
  getCities,
  getAllProducts,
  postOrdersExcel,
  HeaderStyle,
  RowStyle,
  getBrouillon,
  handleErrorLimitOrder,
} from "setup/services";
import Swal from "sweetalert2";
import useAuth from "hooks/useAuth";
import { TypeContext } from "App";
import ButtonLoaded from "components/ButtonLoaded";
import { ToastContainer, toast } from "react-toastify";
import file_excel from "../../../assets/Excel/charger_commandes.xlsx";
import { tableIcons } from "setup/TableIcons";
import ColumnsSheet from "components/Columns/ColumnsSheet";
import UpdateBrouillon from "components/Sheet/UpdateBrouillon";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomBox from "components/CustomBox";
import "react-toastify/dist/ReactToastify.css";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ImporterCommandeExcel = () => {
  const { auth } = useAuth();
  const [, setChangeType] = useContext(TypeContext);
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState("");
  const [shops, setShops] = useState([]);
  const [shopsSelected, setShopsSelected] = useState(null);
  const [formErrors, setFormErrors] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [commandes, setComamndes] = useState([]);
  const [colOrders, setColOrders] = useState(ColumnsSheet());
  const [openModelUpdate, setOpenModelUpdate] = useState(false);
  const handleOpenModelUpdate = () => setOpenModelUpdate(true);
  const handleCloseModelUpdate = () => setOpenModelUpdate(false);
  const [countSelected, setCountSelected] = useState([]);
  const [SelectedCmd, setSelectedCmd] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [update, setUpdate] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);

  const theme = createTheme({
    typography: {
      fontFamily: ['"Segoe UI"', "sans-serif"].join(","),
      fontSize: 14,
    },
  });
  // const CalculMontant = (element) => {
  //   const qty = parseFloat(element[8]);
  //   const price = getPrice(element);
  //   const cost = getFrais(element);
  //   const reduction = getReduction(element);
  //   return qty * price + cost - reduction;
  // };
  const AlertSucess = () => {
    toast.success("Commande(s) importée avec succés", {
      ...ToastStatus,
      onClose: () => {
        setComamndes([]);
      },
    });
  };
  const AlertEchec = () => {
    toast.error("Echéc d'importer les commandes", {
      ...ToastStatus,
      onClose: () => {
        setComamndes([]);
      },
    });
  };

  const errorFile = () => {
    Swal.fire({
      title: "Attention",
      position: "center",
      icon: "error",
      text: "Impossible d'exécuter ce fichier excel",
      showConfirmButton: true,
      confirmButtonColor: "#2399baff",
      timer: 4500,
    });
  };
  // useEffect(() => {
  //   if (errorColumns !== "") {
  //     Swal.fire({
  //       title: "Attention",
  //       position: "center",
  //       icon: "error",
  //       text: "Il y a une erreur incompatible avec la structure de fichier ",
  //       showConfirmButton: true,
  //       confirmButtonColor: "#2399baff",
  //       timer: 4500,
  //     });
  //   }
  // }, [errorColumns]);
  // useEffect(() => {
  //   if (errorImportValidate && errorImport.length > 0) {
  //     Swal.fire({
  //       title: "Attention",
  //       position: "center",
  //       icon: "error",
  //       text: errorImport[0],
  //       showConfirmButton: true,
  //       confirmButtonColor: "#2399baff",
  //       timer: 4500,
  //     }).then((resp) => {
  //       setErrorImport([]);
  //       setComamndes([]);
  //       setErrorImportValidate(false);
  //     });
  //   }
  // }, [errorImportValidate]);
  const getFile = (e) => {
    const reader = new FileReader();
    setFile(e.target.files[0]?.name);
    reader.onload = (e) => {
      const data = e.target.result;
      // console.log(typeof data);
      const workbook = read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { header: 1 });
      let arrayValues = [];
      for (let i = 1; i < json.length; i++) {
        if (json[i]?.length > 0) {
          arrayValues.push(json[i]);
        }
      }
      setComamndes(arrayValues);
    };
    // setFile();
    try {
      reader.readAsBinaryString(e.target.files[0]);
    } catch (error) {
      errorFile();
    }
  };
  useEffect(() => {
    if (!auth.branch) {
      return;
    }
    getAllShops(auth.branch).then((data) => {
      setShops(data);
    });
    getBrouillon(1, auth.branch).then((data) => {
      setDrafts(data);
    });
    getAllCustomers(auth.branch).then((data) => {
      setCustomers(data);
    });
    getAllProducts(auth.branch).then((data) => {
      setProducts(data);
    });
    getAllProvince(auth.branch).then((data) => {
      setProvinces(data);
    });
    getCities(auth.branch).then((data) => {
      setCities(data);
    });
  }, [auth.branch, update]);
  const defaultPropsBoutique = {
    options: shops,
    getOptionLabel: (option) => option.name_shop,
  };
  const validateSuppression = (ids) => {
    if (ids.length > 0) {
      const alert =
        ids.length === 1
          ? "Vous voulez vraiment supprimer le brouillon #" + ids[0] + " ?"
          : `Vous voulez vraiment supprimer les ${ids.length} brouillons `;
      const confirmed = window.confirm(alert);
      if (confirmed) {
        DeleteBrouillon(ids).then(() => {
          setUpdate((prev) => !prev);
          setCountSelected([]);
          setChangeType((prev) => !prev);
        });
      }
    }
  };

  const sendSubmit = (e) => {
    e.preventDefault();
    setFormErrors("");
    setLoaded(true);
    if (shopsSelected !== null) {
      const form = new FormData();
      const data = {
        ...commandes,
      };
      form.append("shop", JSON.stringify(shopsSelected));
      form.append("orders", JSON.stringify(data));
      form.append("user", auth.idUser);
      form.append("branch", auth.branch);
      form.append("file", file);
      const formData = Object.fromEntries(form);
      postOrdersExcel(formData)
        .then(() => {
          setLoaded(false);
          setChangeType((prev) => !prev);
          setUpdate((prev) => !prev);
          AlertSucess();
          setCountSelected([]);
        })
        .catch((error) => {
          handleErrorLimitOrder(error);
          AlertEchec();
          setLoaded(false);
        });
    } else {
      setLoaded(false);
      setFormErrors(
        "Vous devez sélectionner en moins un boutique dans la liste proposée."
      );
    }
  };
  return (
    <>
      <div className="container_import-client">
        {SelectedCmd && SelectedCmd.length > 0 && countSelected.length > 0 && (
          <UpdateBrouillon
            form={SelectedCmd[0]}
            branch={auth.branch}
            setUpdate={setUpdate}
            open={openModelUpdate}
            handleClose={handleCloseModelUpdate}
            shops={shops}
            customers={customers}
            provinces={provinces}
            cities={cities}
            productsDB={products}
            setCities={setCities}
            setChangeType={setChangeType}
            setSuccess={setSuccess}
            auth={auth}
            setCountSelected={setCountSelected}
            setSelectedCmd={setSelectedCmd}
          />
        )}
        <ToastContainer />
        <Title title={"Importer un commande"} />
        <CustomBox title={"Importez vos commandes avec Excel"} />
        <Box
          component={Paper}
          elevation={3}
          padding={2}
          borderRadius={2}
          marginBottom={2}
        >
          <div className="import">
            <Box justifyContent={"space-between"} display={"flex"}>
              <Alert icon={false} severity="info">
                La boutique sélectionnée sera la même que celle du produit
                choisi.
              </Alert>
              <Box display={"flex"} columnGap={1}>
                <Box component={Paper} paddingX={1} height={40}>
                  <Tooltip title="Modifier brouillon">
                    <IconButton
                      onClick={handleOpenModelUpdate}
                      disabled={countSelected.length !== 1}
                    >
                      <EditIcon color="info" fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box component={Paper} paddingX={1} height={40}>
                  <Badge badgeContent={countSelected.length} color="error">
                    <Tooltip title="Supprimer brouillon">
                      <IconButton
                        onClick={() => validateSuppression(countSelected)}
                        disabled={countSelected.length === 0}
                      >
                        <DeleteIcon color="error" fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </Badge>
                </Box>
              </Box>
            </Box>
            <Autocomplete
              {...defaultPropsBoutique}
              className="auto-complete"
              id="shops"
              error={true}
              isOptionEqualToValue={(option, value) =>
                option.id_shop === value.id_shop
              }
              sx={{ width: "392px" }}
              onChange={(event, newValue) => {
                setShopsSelected(newValue);
              }}
              value={shopsSelected}
              renderInput={(params) => (
                <TextField
                  error={formErrors === "" ? false : true}
                  helperText={formErrors === "" ? false : formErrors}
                  {...params}
                  label="Boutiques"
                  variant="outlined"
                  value={shopsSelected}
                />
              )}
            />{" "}
            <div className="import-modele">
              Fichier a importer (
              <span className="modele">
                <a href={file_excel} download="charger_commandes.xlsx">
                  {" "}
                  Modèle <ImDownload3 />
                </a>
              </span>
              )
            </div>
            <form
              style={{ maxWidth: 250 }}
              // className="form_import"
            >
              <div>.xlsx,.xls,.csv</div>

              <div className="label-input-file">
                <Button
                  component="label"
                  title="Charger fichier"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  onChange={getFile}
                  sx={{ marginBottom: 2 }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    placeholder="Charger fichier"
                    title="Charger fichier"
                    accept=".xlsx,.xls"
                    type="file"
                  />
                </Button>
                <Box>{file && file}</Box>
              </div>
              {/* <FileSelect id={"file"} handleChangeFile={getFile} file={file} /> */}
              {/* {!loaded ? (
            <Button
              variant="contained"
              style={{ textTransform: "none" }}
              type="submit"
              size="small"
            >
              Valider
            </Button>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )} */}
              <ButtonLoaded
                success={success}
                loading={loaded}
                handleButtonClick={sendSubmit}
                title={"Importer"}
              />
            </form>
          </div>
        </Box>
      </div>
      <ThemeProvider theme={theme}>
        <MaterialTable
          title={null}
          localization={localizationTable}
          options={{
            pageSize: 10,
            selection: true,
            selectionProps: (rowData) => ({
              checked: countSelected.includes(rowData.idraft),
            }),
            showSelectAllCheckbox: true,
            headerStyle: HeaderStyle(),
            rowStyle: RowStyle(),
          }}
          icons={tableIcons}
          columns={colOrders}
          data={drafts}
          onSelectionChange={(rows) => {
            setCountSelected(rows.map((row) => row.idraft));
            setSelectedCmd([rows[0]]);
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default ImporterCommandeExcel;
