import { Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const WebhookField = ({
  URL_WEBHOOK,
  style = { width: "73.2%", marginX: "auto" },
}) => {
  const [isCopied, onChangeCopie] = useState(false);

  const handleClick = () => {
    // Set onChangeCopie to true immediately when the button is clicked
    onChangeCopie(true);

    // Copy the text to the clipboard
    navigator.clipboard
      .writeText(URL_WEBHOOK)
      .then(() => {
        setTimeout(() => {
          onChangeCopie(false);
        }, 3000);
      })
      .catch((error) => {});
  };
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">Webhook URL :</InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            <Button
              variant="outlined"
              onClick={handleClick}
              startIcon={
                !isCopied ? (
                  <ContentCopyIcon color="primary" />
                ) : (
                  <CheckCircleIcon color="primary" />
                )
              }
            >
              copier
            </Button>
          </InputAdornment>
        ),
      }}
      sx={style}
      value={URL_WEBHOOK}
      variant="outlined"
    />
  );
};

export default WebhookField;
