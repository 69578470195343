import React from "react";

import DomainesMenu from "shared/components/DomainesMenu";
const MenuWoocommerce = ({
  open,
  toggleDrawer,
  items,
  defaultPropsBoutique,
  setDomaines,
}) => {
  return (
    <DomainesMenu
      defaultPropsBoutique={defaultPropsBoutique}
      items={items}
      platforme={"WooCommerce"}
      open={open}
      toggleDrawer={toggleDrawer}
      setDomaines={setDomaines}
    />
  );
};

export default MenuWoocommerce;
