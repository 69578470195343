import {
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import {
  PostOrder,
  ApiBrouillonDelete,
  getCities,
  getCustomer,
  validatePhoneNumber,
  ToastStatus,
} from "setup/services";

const ApiBrouillonUpdate = ({
  form,
  open,
  handleClose,
  setUpdate,
  setChangeType,
  shops,
  customers,
  provinces,
  cities,
  auth,
  productsDB,
  setCities,
  setOrdersValide,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    // borderRadius: 5,
    boxShadow: 24,
    p: 2,
    height: "100%",
    overflowY: "scroll",
  };
  const {
    id_shop,
    id_customer,
    id_province,
    id_city,
    province,
    city,
    boutique,
    customer,
    phone,
    email,
    adresse,
    products,
    idraft_api,
    delivrey,
    referent,
    note,
    reduction,
    methode,
    prefix_draft,
    api_draft,
  } = form;
  const [productsSelected, setProductsSelected] = useState([]);
  const [shopSelected, setShopSelected] = useState(0);
  const [customerSelected, setCustomerSelected] = useState(0);
  const [provinceSelected, setProvinceSelected] = useState(0);
  const [citySelected, setCitySelected] = useState(0);
  const [formData, setFormData] = useState({
    customerData: customer,
    phoneData: phone,
    emailData: email,
    addressData: adresse,
    referentData: referent,
    noteData: note,
  });
  const [formNumber, setFormNumber] = useState({
    delivreyData: delivrey,
    reductionData: reduction,
  });
  const { delivreyData, reductionData } = formNumber;
  const {
    customerData,
    addressData,
    phoneData,
    emailData,
    referentData,
    noteData,
  } = formData;
  const [deskSelected, setDeskSelected] = useState(0);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    id_province: "",
    id_city: "",
    id_shop: "",
    product: {
      ref: "",
      msg: "",
    },
  });
  useEffect(() => {
    if (provinceSelected != 0) {
      const product = provinces.filter(
        (province) => province.id_province === provinceSelected
      );
      if (product?.length > 0) {
        getCities(parseInt(provinceSelected), product[0]?.country_id).then(
          (data) => {
            setCities(data);
          }
        );
      }
    }
  }, [provinceSelected]);
  const getCitiesValue = () => {
    return cities.map((item) => {
      return <MenuItem value={item.id_city}>{item.name_city}</MenuItem>;
    });
    return "";
  };
  const SendValue = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const SendValueFrais = (e) => {
    setFormNumber((prev) => ({
      ...prev,
      [e.target.id]: parseInt(e.target.value),
    }));
  };
  // const getPrefixBoutique = useCallback(() => {
  //   if (shopSelected !== 0 && shops.length > 0) {
  //     const boutiqueFilter = shops.filter(
  //       (boutique) => boutique.id_shop == shopSelected
  //     );
  //     return boutiqueFilter[0]?.prefix_draft === ""
  //       ? null
  //       : boutiqueFilter[0]?.prefix_draft;
  //   }
  //   return null;
  // }, [shopSelected]);
  // const getPanier = (element) => {
  //   return [
  //     {
  //       ref: produitSelected,
  //       price: priceData,
  //       qty: qtyData,
  //     },
  //   ];
  // };

  // const CalculMontant = (element) => {
  //   const qty = qtyData;
  //   const price = priceData;
  //   const delivrey = delivreyData;
  //   const reduction = reductionData;
  //   return qty * price + delivrey - reduction;
  // };
  // Cannot read properties of undefined (reading 'value')
  useEffect(() => {
    if (id_shop !== null) {
      const boutiqueFilter = shops?.filter((item) => item.id_shop == id_shop);
      boutiqueFilter.length === 0
        ? setShopSelected(0)
        : setShopSelected(id_shop);
    }
    methode == 1 ? setDeskSelected(1) : setDeskSelected(0);
    if (customerSelected !== 0) {
      getCustomer(customerSelected)
        .then((customer) => {
          setFormData({
            // customerData: customer[0].nom_customer,
            phoneData: customer[0].phone,
            emailData: customer[0].email,
            addressData: customer[0].address,
          });
          setProvinceSelected(customer[0].id_province);
          setCitySelected(customer[0].id_city);
        })
        .catch((error) => {
          // setErrorMessage(error.response.data.errors[0].msg);
        });
      // setDisabledInputs(true);
    } else {
      setDisabledInputs(false);
      setFormData((prev) => ({
        ...prev,
        ["customerData"]: customer,
        ["phoneData"]: phone,
        ["emailData"]: email,
        ["addressData"]: adresse,
      }));

      if (id_customer !== null) {
        const customerFilter = customers.filter(
          (item) => item.id_customer == id_customer
        );
        customerFilter.length === 0
          ? setCustomerSelected(0)
          : setCustomerSelected(id_customer);
      }
      if (id_province !== null) {
        const provinceFilter = provinces.filter(
          (item) => item.id_province == id_province
        );
        provinceFilter.length === 0
          ? setProvinceSelected(0)
          : setProvinceSelected(id_province);
      }
      if (id_city !== null && provinceSelected !== 0) {
        const cityFilter = cities.filter((item) => item.id_city == id_city);
        cityFilter.length === 0 ? setCitySelected(0) : setCitySelected(id_city);
      }
      if (id_city !== null && provinceSelected === 0) {
        setCitySelected(id_city);
      }
      if (id_city !== null && id_province !== null && provinceSelected !== 0) {
        setCitySelected(id_city);
      }
      const initialProduitsSelected =
        products.length > 0 &&
        products.map((item) => ({
          ref: item.ref,
          qty: item.qty,
          price: item.price,
          isDisabled: !productsDB.some((option) => {
            return option.name_product === item.ref;
          }),
        }));
      setProductsSelected(initialProduitsSelected);
    }
  }, [customerSelected]);
  const errorsForm = [
    "Vous devez sélectionner une wilaya dans la liste proposée.",
    "Vous devez sélectionner une commune dans la liste proposée.",
    "Le nom du client doit comporter au moins 4 caractères.",
    "Le format du numéro de téléphone est incorrect ",
    "Vous devez sélectionner un produit dans la liste proposée.",
  ];
  const SendForm = () => {
    setFormErrors({
      name: "",
      phone: "",
      id_province: "",
      id_city: "",
      id_shop: "",
      product: {
        ref: "",
        msg: "",
      },
    });

    const PhoneIsValide = validatePhoneNumber(phoneData);
    // Vérifie si shopSelected, wilayaSelected, et citySelected sont égaux à 0
    if (
      provinceSelected === 0 ||
      citySelected === 0 ||
      // produitSelected === 0 ||
      !PhoneIsValide ||
      (customerSelected === 0 && customerData === "")
    ) {
      // Met à jour l'état formErrors avec les erreurs appropriées
      setFormErrors({
        id_province: provinceSelected === 0 ? errorsForm[0] : "",
        id_city: citySelected === 0 ? errorsForm[1] : "",
        name:
          customerSelected === 0 && customerData === "" ? errorsForm[2] : "",
        phone: PhoneIsValide === false ? errorsForm[3] : "",
        // ref: produitSelected === 0 ? errorsForm[4] : "",
      });
    } else {
      let HaveError = false;
      for (const element of productsSelected) {
        const List = productsDB.filter(
          (product) => product.ref === element.ref
        );
        if (List.length === 0) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            product: {
              ref: element.ref,
              msg: "Vous devez sélectionner un produit dans la liste proposée.",
            },
          }));
          HaveError = true;
          break;
        }
      }

      if (!HaveError) {
        setOrdersValide((prev) => ["in"]);
        const formDataFile = new FormData();
        formDataFile.append("id_customer", customerSelected);
        formDataFile.append("id_shop", shopSelected);
        formDataFile.append("prefix", prefix_draft);
        formDataFile.append("api_order", api_draft);
        formDataFile.append("type", 0);
        formDataFile.append("methode", deskSelected);
        formDataFile.append("note", noteData == undefined ? "" : noteData);
        formDataFile.append("referent", referentData);
        formDataFile.append("delivrey", delivreyData);
        formDataFile.append("reduction", reductionData);
        // formDataFile.append(
        //   "montant",
        //   getSousResoult + delivreyData - reductionData
        // );
        formDataFile.append("cart", JSON.stringify(productsSelected));
        formDataFile.append("name", customerData);
        formDataFile.append("phone", phoneData);
        formDataFile.append("email", emailData);
        formDataFile.append(
          "address",
          addressData == undefined ? "" : addressData
        );
        formDataFile.append("province", provinceSelected);
        formDataFile.append("city", citySelected);
        formDataFile.append("branch", auth.branch);
        formDataFile.append("action", 1);
        formDataFile.append("user", auth?.idUser);
        const obj = Object.fromEntries(formDataFile);
        setOrdersValide((prev) => [...prev, "in"]);
        PostOrder(obj).then((response) => {
          // console.log(valueSuccess"in success")
          ApiBrouillonDelete([idraft_api]).then(() => {
            setChangeType((prev) => !prev);
            toast.success("Shopify commande(s) a été synchroniser", {
              ...ToastStatus,
            });
            setUpdate((prev) => !prev);
            handleClose();
          });
        });
      }
    }
  };
  const getSousResoult = useMemo(() => {
    let result = 0;
    productsSelected.forEach((product) => {
      result += product.qty * product.price;
    });
    return result;
  }, [productsSelected]);
  const handleProduitChange = (event, index) => {
    const { name, value } = event.target;

    setProductsSelected((prevProduitsSelected) => {
      const updatedProduitsSelected = [...prevProduitsSelected];
      updatedProduitsSelected[index] = {
        ...updatedProduitsSelected[index],
        [name]: value,
      };
      return updatedProduitsSelected;
    });
  };
  const ListeProduct = () => {
    return (
      products?.length > 0 &&
      products.map((product, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <FormControl
              sx={{ width: "340px" }}
              variant="outlined"
              className="update_input-Brouillon"
              error={
                formErrors.product?.ref !== productsSelected[index]?.ref
                  ? false
                  : true
              }
            >
              <InputLabel className="label-methode">Produit-{index}</InputLabel>
              <Select
                id={product.ref}
                onChange={(event) => handleProduitChange(event, index)}
                label={`Produit-${index}`}
                name={`ref`}
                value={productsSelected[index]?.ref}
                error={
                  formErrors.product?.ref !== productsSelected[index]?.ref
                    ? false
                    : true
                }
              >
                {productsSelected[index]?.isDisabled && (
                  <MenuItem value={productsSelected[index]?.ref} disabled>
                    Produit: {productsSelected[index]?.ref}
                  </MenuItem>
                )}
                {productsDB &&
                  productsDB.length > 0 &&
                  productsDB.map((item) => (
                    //   value={age}
                    <MenuItem value={item.name_product}>
                      {item.name_product}
                    </MenuItem>
                  ))}
              </Select>
              {formErrors.product?.ref === productsSelected[index]?.ref && (
                <FormHelperText>{formErrors.product?.msg}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <div
            className="select-d-flex update_display-brouillon"
            style={{ justifyContent: "center" }}
          >
            <TextField
              label={`Qte-${index}`}
              name={`qty`}
              variant="outlined"
              className="update_input-Brouillon_part"
              value={productsSelected[index]?.qty}
              onChange={(event) => handleProduitChange(event, index)}
              // required
              type="number"
              inputProps={{ min: "1" }}
            />
            <TextField
              label={`Prix unitaire-${index}`}
              name={`price`}
              variant="outlined"
              className="update_input-Brouillon_part"
              type="number"
              inputProps={{ min: "0" }}
              onChange={(event) => handleProduitChange(event, index)}
              value={productsSelected[index]?.price}
              // required
            />
          </div>
        </div>
      ))
    );
  };
  return (
    <Modal open={open} onClose={handleClose} className="update_modal-brouillon">
      <Box
        marginTop={3}
        padding={4}
        maxWidth={800}
        display={"flex"}
        flexDirection={"column"}
        sx={style}
      >
        <FormControl
          variant="outlined"
          className="update_input-Brouillon"
          error={formErrors.id_shop === "" ? false : true}
        >
          <InputLabel id="label-methode">Boutique</InputLabel>
          <Select
            onChange={(e) => setShopSelected(parseInt(e.target.value))}
            label="Boutique"
            value={shopSelected}
            error={formErrors.id_shop === "" ? false : true}
          >
            {shopSelected === 0 && (
              <MenuItem disabled value={0}>
                Boutique: {boutique}
              </MenuItem>
            )}
            {shops &&
              shops.length > 0 &&
              shops.map((boutique) => (
                //   value={age}
                <MenuItem value={boutique.id_shop}>
                  {boutique.name_shop}
                </MenuItem>
              ))}
          </Select>
          {formErrors.id_shop !== "" && (
            <FormHelperText>{formErrors.id_shop}</FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined" className="update_input-Brouillon">
          <InputLabel id="label-methode">Client</InputLabel>
          <Select
            onChange={(e) => setCustomerSelected(parseInt(e.target.value))}
            label="Client"
            value={customerSelected}
          >
            {customers &&
              customers.length > 0 &&
              customers.map((item) => (
                //   value={age}
                <MenuItem value={item.id_customer}>
                  {item.name_customer}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {customerSelected === 0 && (
          <TextField
            className="update_input-Brouillon"
            id="customerData"
            value={customerData}
            label="Nom"
            error={formErrors.name === "" ? false : true}
            helperText={formErrors.name === "" ? false : formErrors.name}
            onChange={(e) => SendValue(e)}
            variant="outlined"
          />
        )}
        <div className="select-d-flex" style={{ justifyContent: "center" }}>
          <TextField
            label="Téléphone 1"
            id="phoneData"
            variant="outlined"
            onChange={(e) => SendValue(e)}
            error={formErrors.phone === "" ? false : true}
            helperText={formErrors.phone === "" ? false : formErrors.phone}
            className="update_input-Brouillon_part"
            value={phoneData}
            disabled={disabledInputs}

            // required
          />
          <TextField
            label="Email / Téléphone 2"
            variant="outlined"
            id="emailData"
            className="update_input-Brouillon_part"
            value={emailData}
            disabled={disabledInputs}
            onChange={(e) => SendValue(e)}

            // required
          />
        </div>
        <div
          className="select-d-flex update_display-brouillon"
          style={{ justifyContent: "center" }}
        >
          <FormControl
            variant="outlined"
            className="update_input-Brouillon_part"
            error={formErrors.id_province === "" ? false : true}
          >
            <InputLabel id="label-methode">Wilaya</InputLabel>
            <Select
              onChange={(e) => setProvinceSelected(parseInt(e.target.value))}
              label="Wilaya"
              value={provinceSelected}
              disabled={disabledInputs}
              error={formErrors.id_province === "" ? false : true}
            >
              {provinceSelected === 0 && (
                <MenuItem disabled value={0}>
                  Wilaya: {province}
                </MenuItem>
              )}
              {provinces &&
                provinces.length > 0 &&
                provinces.map((item) => (
                  //   value={age}
                  <MenuItem value={item.id_province}>
                    {item.name_province}
                  </MenuItem>
                ))}
            </Select>
            {formErrors.id_province !== "" && (
              <FormHelperText>{formErrors.id_province}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            className="update_input-Brouillon_part"
            error={formErrors.id_city === "" ? false : true}
          >
            <InputLabel id="label-methode">Commune</InputLabel>
            <Select
              onChange={(e) => setCitySelected(parseInt(e.target.value))}
              label="Commune"
              value={citySelected}
              disabled={disabledInputs}
              error={formErrors.id_city === "" ? false : true}
            >
              {citySelected === 0 && (
                <MenuItem disabled value={0}>
                  Commune: {city}
                </MenuItem>
              )}
              {cities?.length > 0 && getCitiesValue()}
            </Select>
            {formErrors.id_city !== "" && (
              <FormHelperText>{formErrors.id_city}</FormHelperText>
            )}
          </FormControl>
        </div>
        <TextField
          // style={{ maxWidth: 238 }}
          label="Adresse"
          variant="outlined"
          className="update_input-Brouillon"
          disabled={disabledInputs}
          id="addressData"
          value={addressData}
          onChange={(e) => SendValue(e)}
          // required
        />

        <TextField
          label="Remarques"
          variant="outlined"
          className="update_input-Brouillon"
          value={noteData}
          id="noteData"
          onChange={(e) => SendValue(e)}
        />
        <TextField
          label="Réfèrent"
          variant="outlined"
          className="update_input-Brouillon"
          value={referentData}
          id="referentData"
          onChange={(e) => SendValue(e)}

          // required
        />
        <FormControl variant="outlined" className="update_input-Brouillon">
          <InputLabel id="label-methode">Stop Desk</InputLabel>
          <Select
            onChange={(e) => setDeskSelected(parseInt(e.target.value))}
            label="Stop Desk"
            value={deskSelected}
          >
            <MenuItem value={"1"}>Oui</MenuItem>
            <MenuItem value={"0"}>Non</MenuItem>
          </Select>
        </FormControl>
        {ListeProduct()}
        <div className="panier_result">
          <div className="panier-sous_result">
            <div className="panier_result-title">Sous Tolale :</div>{" "}
            <div className="panier_result-infor padding-r">
              {" "}
              {getSousResoult} DZ
            </div>{" "}
          </div>

          <div className="panier-sous_result">
            <div className="panier_result-title">Livraison :</div>{" "}
            <div className="panier_result-infor">
              {" "}
              <TextField
                id="delivreyData"
                className="label_txtField input_panier"
                type="number"
                value={delivreyData}
                onChange={(e) => SendValueFrais(e)}
                inputProps={{ min: "0" }}
              />
            </div>{" "}
          </div>
          <div className="panier-sous_result">
            <div className="panier_result-title">Réduction :</div>{" "}
            <div className="panier_result-infor">
              {" "}
              <TextField
                onChange={(e) => SendValueFrais(e)}
                id="reductionData"
                className="label_txtField input_panier"
                type="number"
                value={reductionData}
                inputProps={{ min: "0" }}
              />
            </div>{" "}
          </div>
          <div className="panier-sous_result">
            <div className="panier_result-title">Tolale :</div>{" "}
            <div className="panier_result-infor padding-r">
              {getSousResoult + delivreyData - reductionData} DZ
            </div>{" "}
          </div>
        </div>
        <Box padding={4} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            startIcon={<MdEdit />}
            style={{ marginTop: 25 }}
            onClick={() => SendForm()}
          >
            Modifier
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApiBrouillonUpdate;
