const ColumnsWoo = (sendValue) => [
  // {
  //   title: "ID",
  //   field: "api_draft",
  //   cellStyle: {
  //     width: 350,
  //   },
  // },
  {
    title: "Client",
    field: "customer",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Téléphone",
    field: "phone",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Wilaya",
    field: "province",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Commune",
    field: "city",
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Adresse",
    field: "address",
  },

  {
    title: "Produits",
    field: "total",
    render: (params) => {
      let name = [];
      if (params?.products?.length > 0) {
        params.products.forEach((element) => {
          name.push(`{ Produit : ${element.ref},qty : ${element.qty} } `);
        });
        return <div>{name.join(" , ")}</div>;
      }
    },
    cellStyle: {
      width: 300,
    },
  },
];

export default ColumnsWoo;
