import React from "react";

import DomainesMenu from "shared/components/DomainesMenu";
const MenuYoucan = ({
  open,
  toggleDrawer,
  items,
  defaultPropsBoutique,
  setDomaines,
}) => {
  return (
    <DomainesMenu
      defaultPropsBoutique={defaultPropsBoutique}
      items={items}
      platforme={"Youcan"}
      open={open}
      setDomaines={setDomaines}
      toggleDrawer={toggleDrawer}
    />
  );
};

export default MenuYoucan;
