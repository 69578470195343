import { LoadingButton } from "@mui/lab";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
const ButtonLoading = () => {
  return (
    <LoadingButton
      endIcon={<SendIcon />}
      loading
      loadingPosition="end"
      variant="contained"
    >
      <span>Envoyer</span>
    </LoadingButton>
  );
};

export default ButtonLoading;
