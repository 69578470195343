import { createTheme, ThemeProvider } from "@mui/material";
import MaterialTable from "material-table";
import React from "react";
import { HeaderStyle, localizationTable, RowStyle } from "setup/services";
import { tableIcons } from "setup/TableIcons";

const TableWoocommerce = ({
  countSelected,
  setSelectedCmd,
  loaded,
  colCommandes,
  brouillons,
  setCountSelected,
}) => {
  const theme = createTheme({
    typography: {
      fontFamily: ['"Segoe UI"', "sans-serif"].join(","),
      fontSize: 14,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        title={null}
        localization={localizationTable}
        options={{
          pageSize: 10,
          selection: true,
          selectionProps: (rowData) => ({
            checked: countSelected.includes(rowData.idraft_api),
          }),
          showSelectAllCheckbox: true,
          headerStyle: HeaderStyle(),
          rowStyle: RowStyle(),
        }}
        isLoading={loaded}
        icons={tableIcons}
        columns={colCommandes}
        data={brouillons}
        onSelectionChange={(rows) => {
          setCountSelected(rows.map((row) => row.idraft_api));
          setSelectedCmd([rows[0]]);
        }}
      />
    </ThemeProvider>
  );
};

export default TableWoocommerce;
