import CountUp from "react-countup";

const CardTittle = ({ CountOn, title1, title2, type, classVal }) => {
  return (
    <div className="card_orders-admin_title">
      <div className="main-title_card-admin"> {title2}</div>
      {CountOn && (
        <CountUp
          className={
            classVal
              ? ` ${classVal} class card_orders-admin_title1`
              : " class card_orders-admin_title1"
          }
          start={0}
          end={title1}
          suffix={type ? ` ${type} ` : ""}
          duration={2}
          delay={0}
        />
      )}
    </div>
  );
};

export default CardTittle;
