import { BarChart } from "@mui/x-charts/BarChart";
import React, { useEffect, useState } from "react";
import ScrollTrigger from "react-scroll-trigger";

import { grey } from "@mui/material/colors";
import GridViewIcon from "@mui/icons-material/GridView";
import {
  Card,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  GetProvinceTypesOrders,
  getAllOrdersTypesByCount,
  getCAByMonth,
  getCAofOrders,
  getChargesMontant,
  getCountOrders,
  getCountOrdersByCallUsers,
  getDelevreeOrdersByMonth,
  getOrdersBymonths,
  getOrdersStatus,
} from "setup/services";
import useAuth from "hooks/useAuth";
import Title from "components/Title";
import ListCardAdmin from "components/Main/ListCardAdmin";
import { FaUserCircle } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { MdShoppingBag } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import CardTittle from "components/Main/CardTittle";
import { LineChart } from "@mui/x-charts";
import ListCardCall from "components/Main/ListCardCall";
import { getCountCustomers } from "../../setup/services";
import dataRegions from "./ordersRegions.json";
function Main() {
  const { auth } = useAuth();
  const [CountOn1, setCounterOn1] = useState(false);
  const [CountOn2, setCounterOn2] = useState(false);
  const [TypesCount, setTypesCount] = useState([]);
  const [ordersMonth, setOrdersMonth] = useState([]);
  const [ordersUser, setOrdersUser] = useState([]);
  const [delevreebyMonth, setDelevreebyMonth] = useState([]);
  const [delevreebyMonthCount, setDelevreebyMonthCount] = useState(0);
  const [caMonth, setCaMonth] = useState([]);
  const [CA, setCA] = useState([]);

  const [ordersRegion, setOrdersRegion] = useState(dataRegions);
  const [cards, setCards] = useState([
    {
      id: 2,
      icon: <IoMdCart />,
      title: "Nombres commandes",
      value: 0,
      color: ["#1da256", "#48d483"],
      grow: true,
    },
    {
      id: 3,
      icon: <MdShoppingBag />,
      title: "Vente Total",
      value: 0,
      grow: true,
      color: ["#1a50b5", "#2a6ff7"],
    },
    {
      id: 4,
      icon: <BsFillLightningChargeFill />,
      title: "Charge Total",
      value: 0,
      color: ["#e1950e", "#f3cd29"],
    },
    {
      id: 1,
      icon: <FaUserCircle />,
      title: "Nombres clients",
      value: 0,
      color: ["#c012e2", "#eb64fe"],
    },
  ]);
  const initialState = [
    {
      id: 1,
      option: "Last Month",
    },
    {
      id: 2,
      option: "Last Month",
    },
    {
      id: 3,
      option: "Last Month",
    },
    {
      id: 4,
      option: "Last Month",
    },
  ];
  const [optionSelected, setOptionSelected] = useState(initialState);
  const ChnageValueOfCardAdmin = (
    card,
    countCustomers,
    countOrders,
    CA,
    charge
  ) => {
    if (card.id === 1) {
      card.value = countCustomers;
    }
    if (card.id === 2) {
      card.value = countOrders;
    }
    if (card.id === 3) {
      card.value = CA;
    }
    if (card.id === 4) {
      card.value = charge;
    }
    return card;
  };
  const ConvertToRegionForm = (DataWilayas) => {
    const reformulatedTable = DataWilayas.reduce((result, current) => {
      const existingEntry = result.find(
        (entry) => entry.id_province === current.id_province
      );

      if (existingEntry) {
        existingEntry.types.push({
          idtype_order: current.idtype_order,
          type_order: current.type_order,
          nbrOrder: current.nbrOrder,
        });
      } else {
        result.push({
          id_province: current.id_province,
          name_province: current.name_province,
          types: [
            {
              idtype_order: current.idtype_order,
              type_order: current.type_order,
              nbrOrder: current.nbrOrder,
            },
          ],
        });
      }

      return result;
    }, []);

    return reformulatedTable;
  };
  const ConvertToUserCallForm = (DataUsers) => {
    const reformulatedTable = DataUsers.reduce((result, current) => {
      const existingEntry = result.find(
        (entry) => entry.id_user === current.id_user
      );

      // Vérifier si une entrée existante pour cet utilisateur et ce type de commande
      if (existingEntry) {
        const existingType = existingEntry.types.find(
          (type) => type.idtype_order === current.idtype_order
        );

        // Si l'entrée existe déjà, ajoutez simplement le nombre de commandes
        if (existingType) {
          existingType.nbrOrder += current.nbrOrder;
        } else {
          // Sinon, ajoutez une nouvelle entrée pour ce type de commande
          existingEntry.types.push({
            idtype_order: current.idtype_order,
            type_order: current.type_order,
            nbrOrder: current.nbrOrder,
          });
        }
      } else {
        // Si aucune entrée pour cet utilisateur, ajoutez une nouvelle entrée avec le type de commande
        result.push({
          id_user: current.id_user,
          username: current.username,
          types: [
            {
              idtype_order: current.idtype_order,
              type_order: current.type_order,
              nbrOrder: current.nbrOrder,
            },
          ],
        });
      }

      return result;
    }, []);

    return reformulatedTable;
  };

  const RattingTypeOrderUser = (usersData) => {
    usersData.forEach((user) => {
      const totalCmd = user.types.reduce(
        (total, type) => total + type.nbrOrder,
        0
      );

      // Ajouter le taux pour chaque type de commande
      user.types.forEach((type) => {
        type.rate = totalCmd !== 0 ? (type.nbrOrder / totalCmd) * 100 : 0;
      });
      // Ajouter le nouvel attribut pour chaque utilisateur
      user.types.push({
        idtype_order: -1,
        type_order: "Total affectés",
        nbrOrder: totalCmd,
      });
    });

    return usersData;
  };
  const CountDelivreresOrderMonths = (values) => {
    let count = 0;
    values.forEach((element) => {
      count += element.nbrOrder;
    });
    return count;
  };
  useEffect(() => {
    if (auth.branch === undefined) {
      return;
    }
    const id = auth.branch;
    Promise.all([
      getCountCustomers(
        id,
        optionSelected.find((item) => item.id === 1).option
      ),
      getAllOrdersTypesByCount(auth.branch),
      getCountOrders(id, optionSelected.find((item) => item.id === 2).option),
      getCAofOrders(id, optionSelected.find((item) => item.id === 3).option),
      getChargesMontant(
        id,
        optionSelected.find((item) => item.id === 4).option
      ),
      getOrdersBymonths(id),
      GetProvinceTypesOrders(id),
      getCAByMonth(id),
      getDelevreeOrdersByMonth(id),
      getCountOrdersByCallUsers(),
      getOrdersStatus(),
    ]).then(
      ([
        countCustomers,
        countTypes,
        countOrders,
        CAorders,
        Datacharge,
        OrdersMonths,
        DataWilayas,
        CaByMonths,
        delevreebyMonth,
        ordersCallUser,
        ordersStatus,
      ]) => {
        const CaValue = CAorders[0]?.CA;
        setCA(CaValue);
        const value = CaByMonths
          ? CaByMonths?.map((month) =>
              month.id_branch === null ? { ...month, total: 0 } : month
            )
          : [];
        setCaMonth(value);
        const status = ordersStatus.map((status) => ({
          ...status,
          count: 0,
        }));
        let ArrayOrders = [...status];
        countTypes.forEach((type) => {
          status.forEach((status) => {
            if (type.num === status.num) {
              ArrayOrders = ArrayOrders.map((line) =>
                line.num === status.num
                  ? {
                      ...status,
                      count: type.count,
                    }
                  : line
              );
            }
          });
        });
        setTypesCount(ArrayOrders);
        const FilterOrdersBymonth = delevreebyMonth.map((line) =>
          line.id_branch !== id ? { ...line, nbrOrder: 0 } : line
        );
        setDelevreebyMonth(FilterOrdersBymonth);
        setDelevreebyMonthCount(
          CountDelivreresOrderMonths(FilterOrdersBymonth)
        );
        const usersConvert = RattingTypeOrderUser(
          ConvertToUserCallForm(
            ordersCallUser.filter((user) => user.branch_id === id)
          )
        );
        setOrdersUser(usersConvert);
        const WilayasConvert = ConvertToRegionForm(DataWilayas);
        setOrdersRegion(WilayasConvert);

        setOrdersMonth(
          OrdersMonths.map((line) =>
            line.branch_id !== id ? { ...line, number_of_orders: 0 } : line
          )
        );
        const newCards = cards.map((card) => {
          return ChnageValueOfCardAdmin(
            card,
            countCustomers[0]?.nbr_customers,
            countOrders[0]?.nbrOrder,
            CaValue,
            Datacharge[0]?.charges === null ? 0 : Datacharge[0]?.charges
          );
        });
        setCards(newCards);
      }
    );
  }, [auth.branch, optionSelected]);

  const orderysTypes = [
    { title: "Total commandes", value: 37 },
    { title: "En confirmation", value: 100 },
    { title: "En préparation", value: 230 },
    { title: "En dispatch", value: 331 },
    { title: "En livraison", value: 232 },
    { title: "Livrées", value: 137 },
    { title: "En retour", value: 533 },
    { title: "Annulée", value: 533 },
  ];

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
    title1,
    title2,
    title3
  ) {
    return { name, calories, fat, carbs, protein, title1, title2, title3 };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 10, 20, 30),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 9, 10, 3),
    createData("Eclair", 262, 16.0, 24, 6.0, 9, 8, 2),
    createData("Cupcake", 305, 3.7, 67, 4.3, 9, 7, 2),
    createData("Gingerbread", 356, 16.0, 49, 3.9, 8, 12, 4),
  ];
  // const HandlePeriode = (e) => {
  //   const value = parseInt(e.target.value);
  //   setPeriode(value);
  //   if (value !== 5) {
  //     getCountOrdersByPeriode(value, datePicker, datePicker1).then((data) => {
  //       const newCards = cards.map((card) => {
  //         if (card.id === 2) {
  //           card.value = data[0]?.nbrOrder;
  //         }
  //         return card;
  //       });
  //       setCards(newCards);
  //     });
  //   }
  // };
  // const handleFilter = () => {
  //   getCountOrdersByPeriode(periode, datePicker, datePicker1).then((data) => {
  //     const newCards = cards.map((card) => {
  //       if (card.id === 2) {
  //         card.value = data[0]?.nbrOrder;
  //       }
  //       return card;
  //     });
  //     setCards(newCards);
  //   });
  // };
  const TauxLivraison = () => {
    let Total = 0;
    let livree = 0;
    TypesCount?.forEach((type) => {
      Total += type.count;
      if (type.num === 4) {
        livree = type.count;
      }
    });
    return Number((livree * 100) / Total).toFixed(2);
  };

  const getSubTotalRegion = (types) => {
    let total = 0;
    types?.forEach((type) => {
      total += type.nbrOrder;
    });
    return total;
  };
  const getTableBody = () => {
    return (
      <TableBody>
        {ordersRegion?.length > 0 &&
          ordersRegion.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name_province}
              </TableCell>
              <TableCell align="center" scope="row">
                {getSubTotalRegion(row?.types)}
              </TableCell>
              {row?.types?.map((type, index) => {
                return (
                  <React.Fragment key={index}>
                    {type.idtype_order === 0 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                    {type.idtype_order === 1 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                    {type.idtype_order === 2 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                    {type.idtype_order === 3 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                    {type.idtype_order === 4 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                    {type.idtype_order === 5 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                    {type.idtype_order === 6 && (
                      <TableCell align="center">{type.nbrOrder}</TableCell>
                    )}
                  </React.Fragment>
                );
              })}
            </TableRow>
          ))}
      </TableBody>
    );
  };
  const ordersMonthTemp = [
    {
      month: "Janv",
      id_branch: null,
      nbrOrder: 150,
    },
    {
      month: "Fév",
      id_branch: null,
      nbrOrder: 430,
    },
    {
      month: "Mar",
      id_branch: null,
      nbrOrder: 150,
    },
    {
      month: " Avr",
      id_branch: null,
      nbrOrder: 600,
    },
    {
      month: "Mai",
      id_branch: null,
      nbrOrder: 400,
    },
    {
      month: "Juin",
      id_branch: null,
      nbrOrder: 322,
    },
    {
      month: "Juil",
      id_branch: null,
      nbrOrder: 99,
    },
    {
      month: "Août",
      id_branch: null,
      nbrOrder: 222,
    },
    {
      month: "Sept",
      id_branch: null,
      nbrOrder: 650,
    },
    {
      month: "Oct",
      id_branch: null,
      nbrOrder: 450,
    },
    {
      month: "Nov",
      id_branch: null,
      nbrOrder: 66,
    },
    {
      month: "Déc",
      id_branch: null,
      nbrOrder: 790,
    },
  ];
  return (
    <>
      <Title title={"Tableaux de bord"} />
      <div className="header_withselect">
        <div className="header_main-admin">
          <Paper style={{ padding: 7 }}>
            <GridViewIcon
              style={{ color: "#01579b", fontWeight: "700", fontSize: 28 }}
            />
          </Paper>
          <div className="title_main-admin">
            <h3 style={{ margin: 0, color: grey[900], opacity: "0.8" }}>
              Tableau de bord des commandes
            </h3>
            <p
              style={{
                fontSize: 15,
                fontWeight: "500",
                color: grey[700],
                margin: 0,
              }}
            >
              Suivi des statistiques de commandes pour le mois actuelle
            </p>
          </div>
        </div>
        {/* <div className="main_select-period">
          <FormControl>
            <InputLabel id="select_date">Période</InputLabel>
            <Select
              style={{ width: 200, fontSize: 14 }}
              labelId="select_date"
              name="select_methode"
              id="select-codeBar"
              label="Période"
              onChange={(e) => HandlePeriode(e)}
              value={periode}
              size="small"
            >
              <MenuItem value={0}>Aujourd'hui</MenuItem>
              <MenuItem value={1}>Hier</MenuItem>
              <MenuItem value={2}>7 derniers jours</MenuItem>
              <MenuItem value={3}>14 derniers jours</MenuItem>
              <MenuItem value={4}>28 derniers jours</MenuItem>
              <MenuItem value={5}>Période</MenuItem>
            </Select>
          </FormControl>
          {periode === 5 && (
            <div
              className="datePicker_admin"
              style={{ display: "flex", gap: "8px" }}
            >
              <TextField
                style={{ width: 200 }}
                id="name"
                type="date"
                value={datePicker}
                label="De"
                className="margin-0 label_txtField select"
                onChange={(e) => setDatePicker(e.target.value)}
                inputProps={{ shrink: true }}
                variant="outlined"
                size="small"
              />
              <TextField
                style={{ width: 200 }}
                id="name"
                type="date"
                value={datePicker1}
                label="à"
                className="label_txtField select margin-0"
                onChange={(e) => setDatePicker1(e.target.value)}
                inputProps={{ shrink: true }}
                variant="outlined"
                size="small"
              />
              <IconButton onClick={() => handleFilter()} color="primary">
                <FilterAltIcon />
              </IconButton>
            </div>
          )}
        </div> */}
      </div>
      <div className="container_main-admin">
        <ListCardAdmin
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          devise={auth.devise}
          cards={cards}
        />
        <ScrollTrigger
          onEnter={() => setCounterOn1(true)}
          onExit={() => setCounterOn1(false)}
        >
          <div
            className="main_orders-admin"
            style={{ display: "flex", gap: "20px" }}
          >
            <Card
              sx={{ minHeight: 533, padding: "20px", borderRadius: "15px" }}
              elevation={3}
              className="card_orders-admin"
            >
              <div
                className="card_admin_header_title"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Commandes
              </div>
              <div className="card_orders-admin_header">
                <CardTittle
                  CountOn={CountOn1}
                  title1={(cards[1].value / 12).toFixed(2)}
                  title2={"Commandes Moyennes"}
                />
              </div>
              {ordersMonth?.length > 0 && (
                <BarChart
                  slotProps={{
                    bar: {
                      style: { height: 10 },
                      clipPath: `inset(0px round 10px 10px 10px 10px)`,
                    },
                  }}
                  colors={["#63A5F9"]}
                  height={400}
                  sx={{ borderRadius: "25px" }}
                  layout="horizontal"
                  yAxis={[
                    {
                      scaleType: "band",
                      data: ordersMonth?.map((order) => order.month),
                    },
                  ]}
                  series={[
                    {
                      data: ordersMonth?.map((order) => order.number_of_orders),
                    },
                  ]}
                />
              )}
              {/* {ordersMonth?.length > 0 && (
                <BarChart
                  slotProps={{
                    bar: {
                      style: { height: 10 },
                      clipPath: `inset(0px round 10px 10px 10px 10px)`,
                    },
                  }}
                  colors={["#63A5F9"]}
                  height={400}
                  sx={{ borderRadius: "25px" }}
                  layout="horizontal"
                  yAxis={[
                    {
                      scaleType: "band",
                      data: ordersMonth?.map((order) => order.month),
                    },
                  ]}
                  series={[
                    {
                      data: ordersMonth?.map((order) => order.number_of_orders),
                    },
                  ]}
                />
              )} */}
            </Card>

            <Card
              sx={{ minHeight: 533, padding: "20px", borderRadius: "15px" }}
              elevation={3}
              className="card_status-admin"
            >
              <div
                className="card_admin_header_title"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Statut des commandes
              </div>
              <CardTittle
                CountOn={CountOn1}
                title1={TauxLivraison()}
                title2={"Taux de livraison"}
                type={"%"}
              />
              {TypesCount.map((item, index) => (
                <ListItem key={index} component="a">
                  <ListItemText primary={item.title} />
                  <span
                    className="main-title_card-admin"
                    style={{ fontWeight: "bold", color: "#63A5F9" }}
                  >
                    {item.count}
                  </span>
                </ListItem>
              ))}
            </Card>
          </div>
        </ScrollTrigger>
        <Paper
          className="admin-table_wilaya"
          elevation={3}
          sx={{ width: "100%", overflow: "hidden" }}
        >
          <TableContainer sx={{ height: 440 }}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "600" }}>Wilaya</TableCell>

                  {orderysTypes.map((type, index) => (
                    <TableCell style={{ fontWeight: "600" }} key={index}>
                      {type.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {getTableBody()}
            </Table>
          </TableContainer>
        </Paper>
        <ScrollTrigger
          onEnter={() => setCounterOn2(true)}
          onExit={() => setCounterOn2(false)}
        >
          <div className="main_orders-admin">
            <Card
              elevation={3}
              sx={{ minHeight: 403 }}
              className="card_orders-admin"
            >
              <div className="card_admin_header_title">Commandes Terminées</div>
              <div className="card_orders-admin_header">
                <CardTittle
                  CountOn={CountOn2}
                  title1={delevreebyMonthCount}
                  title2={"TOTAL "}
                />
              </div>
              {delevreebyMonth?.length > 9 && (
                <BarChart
                  height={270}
                  slotProps={{
                    bar: {
                      style: { width: 10 },
                      clipPath: `inset(0px round 10px 10px 10px 10px)`,
                    },
                  }}
                  colors={["#238dab"]}
                  // width={750}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: ordersMonth.map((order) => order.month),
                    },
                  ]}
                  series={[
                    {
                      data: ordersMonth.map((order) =>
                        order.nbrOrder === null ? 0 : order.nbrOrder
                      ),
                    },
                  ]}
                />
              )}
            </Card>
            <Card
              elevation={3}
              sx={{ minHeight: 403 }}
              className="card_orders-admin"
            >
              <div className="card_admin_header_title">Ventes Total</div>
              <div className="card_orders-admin_header">
                <CardTittle
                  CountOn={CountOn2}
                  title1={CA}
                  title2={"TOTAL"}
                  type={auth.devise}
                />
              </div>
              {/* {ordersMonth.length > 0 && (
                <LineChart
                  colors={["#F86B2C"]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: ordersMonth?.map((order) => order.month),
                    },
                  ]}
                  series={[
                    {
                      data: ordersMonth?.map((order) =>
                        order.total === null ? 0 : order.number_of_orders
                      ),
                    },
                  ]}
                  // width={450}
                  height={270}
                />
              )}
            </Card> */}
              {caMonth.length > 0 && (
                <LineChart
                  colors={["#F7B25B"]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: caMonth?.map((order) => order.month),
                    },
                  ]}
                  series={[
                    {
                      data: caMonth?.map((order) =>
                        order.total === null ? 0 : order.total
                      ),
                    },
                  ]}
                  // width={450}
                  height={270}
                />
              )}
            </Card>
          </div>
        </ScrollTrigger>

        <ListCardCall lists={ordersUser} />
      </div>
    </>
  );
}

export default Main;
