import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import ButtonLoaded from "components/ButtonLoaded";
const DialogFiles = ({
  files,
  file,
  setFile,
  handleClose,
  open,
  sendOrder,
  loaded,
  success,
  auth,
  sendCharge,
  valider,
  isSheet,
}) => {
  return (
    <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
      <DialogTitle>Importer des commandes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          choisir un document pour importer des commandes Google sheet
        </DialogContentText>
        <FormControl variant="standard" className="auto-complete">
          <Select
            labelId="label-methode"
            id="methode"
            //   value={age}
            onChange={(e) => setFile(e.target.value)}
            label="Documents"
            // value={file}
          >
            {files &&
              files.length > 0 &&
              files.map((file) => (
                <MenuItem value={file.id}>{file.name}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <ButtonLoaded
          success={success}
          loading={loaded}
          disabled={valider}
          handleButtonClick={sendOrder}
          title={"Valider"}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogFiles;
