import React, { useContext, useEffect, useState } from "react";
import {
  ThemeProvider,
  createTheme,
  Box,
  Badge,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";

import MaterialTable from "material-table";
import { tableIcons } from "../../../setup/TableIcons";
import "react-toastify/dist/ReactToastify.css";

import {
  ApiBrouillonDelete,
  ToastStatus,
  getAllShops,
  getAllCustomers,
  getAllProducts,
  getAllProvince,
  getBrouillonsApi,
  UpdateOrdersYoucan,
  localizationTable,
  HeaderStyle,
  RowStyle,
  AuthYoucan,
  CatchError,
  getAllSalesPlatforme,
} from "../../../setup/services";
import ColumnsWoo from "../../../components/Columns/ColumnsWoo";
import Title from "components/Title";
import { TypeContext } from "App";

import { ListBrouillons } from "setup/ApiHelpe";
import useAuth from "hooks/useAuth";
import ApiBrouillonUpdate from "components/Shopify/ApiBrouillonUpdate";
import { ToastContainer, toast } from "react-toastify";
import CustomBox from "components/CustomBox";
import HeaderYoucan from "components/Youcan/HeaderYoucan";
import TableYoucan from "components/Youcan/TableYoucan";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MenuYoucan from "components/Youcan/MenuYoucan";
import AffectationYoucan from "components/Youcan/AffectationYoucan";
const ListeYoucan = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [, setChangeType] = useContext(TypeContext);
  const [loaded, setLoaded] = useState(false);

  const [update, setUpdate] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [Provinces, setProvinces] = useState([]);
  const [Cities, setCities] = useState([]);
  const [produits, setProduits] = useState([]);
  const [countSelected, setCountSelected] = useState([]);
  const [SelectedOrder, setSelectedOrder] = useState([]);
  const [colCommandes] = useState(ColumnsWoo());
  const [openModelUpdate, setOpenModelUpdate] = useState(false);
  const handleOpenModelUpdate = () => setOpenModelUpdate(true);
  const handleCloseModelUpdate = () => setOpenModelUpdate(false);
  const [brouillons, setBrouillons] = useState([]);
  const [shops, setShops] = useState([]);
  const [ordersValide, setOrdersValide] = useState([]);
  const [success, setSuccess] = useState(false);
  const [domaines, setDomaines] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalAffectation, setModalAffectation] = useState(false);
  const handleOpenAffectation = () => setModalAffectation(true);
  const handleCloseAffectation = () => setModalAffectation(false);
  const defaultPropsBoutique = {
    options: shops || [],
    getOptionLabel: (option) => option.name_shop,
  };
  useEffect(() => {
    if (success && ordersValide.length > 0) {
      // commande length- comdBreuill
      toast.success(ordersValide.length + " commande(s) créer avec succés", {
        ...ToastStatus,
        onClose: () => {
          setSuccess(false);
          setOrdersValide([]);
          setChangeType((prev) => !prev);
          setUpdate((prev) => !prev);
          setCountSelected([]);
        },
      });
    }
  }, [success]);
  const gotError = (error) => {
    Swal.fire({
      title: "Attention !",
      text: error,
      showConfirmButton: false,
      icon: "error",
      timer: 2000,
    }).then(() => {
      setLoaded(false);
      setLoaded(false);
    });
  };
  const getSuccessMessage = () => {
    toast.success("Les boutiques Youcan ont été synchronisées.", {
      ...ToastStatus,
      onClose: () => {
        setUpdate((prev) => !prev);
        setCountSelected([]);
      },
    });
  };
  useEffect(() => {
    if (!auth.branch) {
      return;
    }
    const branch = auth.branch;
    getAllProvince(branch).then((data) => {
      setProvinces(data);
    });
    getAllShops(branch).then((data) => {
      setShops(data);
    });
    getAllProducts(branch).then((data) => {
      setProduits(data);
    });
    getAllCustomers(undefined, branch).then((data) => {
      setCustomers(data);
    });
    getAllSalesPlatforme(auth.branch, "Youcan").then((data) => {
      setDomaines(data);
    });
    getBrouillonsApi(1, branch)
      .then((data) => {
        const brouillons = ListBrouillons(data);
        setBrouillons(brouillons);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [update, auth.branch]);

  // const remove = (ref) => {
  //   DeleteListCmd(ref);
  //   setUpdate((prev) => !prev);
  // };
  // const handleSelectAll = () => {
  //   setCountSelected(commandes.map((row) => row.prefix_draft));
  // };
  const validateSuppression = (ids) => {
    if (ids.length > 0) {
      const alert =
        ids.length === 1
          ? "Vous voulez vraiment supprimer le brouillon #" + ids[0] + " ?"
          : `Vous voulez vraiment supprimer les ${ids.length} brouillons ?`;
      const confirmed = window.confirm(alert);
      if (confirmed) {
        ApiBrouillonDelete(ids).then(() => {
          setUpdate((prev) => !prev);
          setChangeType((prev) => !prev);
          toast.success("Les brouillons ont été supprimées.", {
            ...ToastStatus,
          });
        });
      }
    }
  };

  const handleAuth = () => {
    AuthYoucan()
      .then((data) => {
        console.log(data);
        window.open(data?.data, "_self");
      })
      .catch(() => {
        CatchError();
      });
  };
  const [openModel, setOpenModel] = useState(false);
  const handleOpenMenu = () => setOpenModel(true);
  const handleCloseMenu = () => () => setOpenModel(false);

  return (
    <>
      {/* <div className="header_addCmd-d_flex">
        <SiWoo className="icon_cmd icon_woocommerce" />
        <p>Commandes Woocommerce</p>
      </div> */}
      <Title title={"Commandes Youcan"} />
      <CustomBox title={"Importez vos commandes avec Youcan"} />
      {SelectedOrder &&
        SelectedOrder.length > 0 &&
        countSelected.length > 0 && (
          <ApiBrouillonUpdate
            branch={auth.branch}
            form={SelectedOrder[0]}
            setUpdate={setUpdate}
            open={openModelUpdate}
            handleClose={handleCloseModelUpdate}
            shops={shops}
            customers={customers}
            provinces={Provinces}
            cities={Cities}
            productsDB={produits}
            setCities={setCities}
            setChangeType={setChangeType}
            setSuccess={setSuccess}
            setOrdersValide={setOrdersValide}
            auth={auth}
          />
        )}
      <MenuYoucan
        defaultPropsBoutique={defaultPropsBoutique}
        items={domaines}
        open={openModel}
        setDomaines={setDomaines}
        toggleDrawer={handleCloseMenu}
      />
      <AffectationYoucan
        branch={auth.branch}
        email={email}
        password={password}
        setEmail={setEmail}
        setPassword={setPassword}
        open={modalAffectation}
        handleClose={handleCloseAffectation}
        shops={shops}
        setDomaines={setDomaines}
        platforme="youcan"
      />
      <HeaderYoucan
        logingStore={handleAuth}
        countSelected={countSelected}
        handleOpenModelUpdate={handleOpenModelUpdate}
        validateSuppression={validateSuppression}
        handleMenu={handleOpenMenu}
        handleOpenAffectation={handleOpenAffectation}
      />
      <TableYoucan
        brouillons={brouillons}
        colCommandes={colCommandes}
        countSelected={countSelected}
        loaded={loaded}
        setCountSelected={setCountSelected}
        setSelectedOrder={setSelectedOrder}
      />
      <ToastContainer position="absolute" />
    </>
  );
};

export default ListeYoucan;
