import { Badge, Box, Button, IconButton, Paper, Tooltip } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import logo from "../../assets/images/LogoYoucan.png";
import { PiPlugChargingBold as ChargeIcon } from "react-icons/pi";
import { TbTableFilled } from "react-icons/tb";

const HeaderYoucan = ({
  handleOpenModelUpdate,
  countSelected,
  validateSuppression,
  logingStore,
  handleMenu,
  handleOpenAffectation,
}) => {
  return (
    <Box
      marginBottom={2}
      elevation={3}
      borderRadius={2}
      padding={2}
      component={Paper}
    >
      <Box
        display={"flex"}
        marginBottom={2}
        alignItems={"center"}
        columnGap={1}
        paddingLeft={2}
        paddingTop={2}
      >
        <img src={logo} alt="Youcan Logo" width={80} height={80} />
        <Box fontWeight={"700"}>
          Youcan API
          <Box color={"GrayText"} fontSize={12}>
            Youcan Orders List
          </Box>{" "}
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <div className="sheet-btns">
          <Button
            startIcon={<ChargeIcon fontSize={15} />}
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={handleOpenAffectation}
          >
            Se connecter
          </Button>
          <Button
            startIcon={<TbTableFilled color="#01579b" fontSize={20} />}
            style={{ textTransform: "none" }}
            variant="outlined"
            // color="warning"
            onClick={handleMenu}
          >
            Stores.youcan.shop
          </Button>
        </div>

        <Box
          display={"flex"}
          justifyContent={"end"}
          columnGap={1}
          marginRight={4}
          py={2}
        >
          <Box component={Paper} paddingX={1}>
            <Tooltip title="Modifier brouillon">
              <IconButton
                onClick={handleOpenModelUpdate}
                disabled={countSelected.length !== 1}
              >
                <EditIcon color="info" fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box component={Paper} paddingX={1}>
            <Badge badgeContent={countSelected.length} color="error">
              <Tooltip title="Supprimer brouillon">
                <IconButton
                  onClick={() => validateSuppression(countSelected)}
                  disabled={countSelected.length === 0}
                >
                  <DeleteIcon color="error" fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Badge>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderYoucan;
