const ColumnsSheet = (sendValue) => [
  {
    title: "Document",
    field: "file",
  },

  {
    title: "Boutique",
    render: (params) => {
      return params.shop == "" ? "-" : params.shop;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Client",

    render: (params) => {
      return params.customer == "" ? "-" : params.customer;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Téléphone",
    render: (params) => {
      return params.phone == "" ? "-" : params.phone;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Wilaya",
    render: (params) => {
      return params.province == "" ? "-" : params.province;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Commune",
    render: (params) => {
      return params.city == "" ? "-" : params.city;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Adresse",
    render: (params) => {
      return params.address == "" ? "-" : params.address;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Produit",
    render: (params) => {
      return params.name == "" ? "-" : params.name;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Quantité",
    render: (params) => {
      return params.qty == "" ? "-" : params.qty;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Prix unitaire",
    render: (params) => {
      return params.price == "" ? "-" : params.price;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Remarques",
    render: (params) => {
      return params.note == "" ? "-" : params.note;
    },
    cellStyle: {
      textAlign: "center",
    },
  },

  {
    title: "Stop Desk",
    render: (params) => {
      return params.stopDesk === 1 ? "Oui" : "Non";
    },
  },
];

export default ColumnsSheet;
