import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const {
  TableBody,
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
  Box,
  Paper,
} = require("@mui/material");
const BodyDomaines = ({
  items,
  defaultPropsBoutique,
  setLineSelected,
  UpdateSetting,
  DeleteSetting,
}) => {
  return (
    <TableBody>
      {items?.map((item, index) => (
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {item.column2}
          </TableCell>
          <TableCell align="right">
            <Autocomplete
              {...defaultPropsBoutique}
              sx={{ minWidth: 300 }}
              className="auto-complete"
              id="boutiques"
              size="small"
              isOptionEqualToValue={(option, value) =>
                option ? option?.id_shop === value?.id_shop : ""
              }
              onChange={(event, newValue) => {
                setLineSelected({
                  id: item?.id,
                  id_shop: newValue?.id_shop,
                });
              }}
              defaultValue={{
                id_shop: item?.id_shop,
                name_shop: item?.name_shop,
              }}
              renderInput={(params) => (
                <TextField {...params} label="" variant="outlined" />
              )}
            />
          </TableCell>
          <TableCell align="center">
            <Box
              component={Paper}
              onClick={UpdateSetting}
              sx={{ cursor: "pointer" }}
              display={"flex"}
              paddingY={1}
              width={50}
              justifyContent={"center"}
            >
              <EditIcon color="info" />
            </Box>
          </TableCell>
          <TableCell align="center">
            <Box
              component={Paper}
              sx={{ cursor: "pointer" }}
              display={"flex"}
              paddingY={1}
              width={50}
              justifyContent={"center"}
              alignItems={"center"}
              onClick={() => DeleteSetting(item.id)}
            >
              <DeleteIcon color="error" />
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
export default BodyDomaines;
