import { Card, ListItem, ListItemText, Rating } from "@mui/material";
import call from "../../assets/images/call-center.png";
const CardCall = ({ UserList }) => {
  const ListItemHtml = () => {
    return UserList.types.map((item, index) => (
      <ListItem
        secondaryAction={
          <span className="main-title_card-admin">
            {item.rate !== undefined ? Number(item.rate).toFixed(1) + " %" : ""}
          </span>
        }
        key={index}
        component="a"
      >
        <ListItemText
          className={item.idtype_order === -1 ? "item-admin_font" : ""}
          primary={item.type_order + " " + item.nbrOrder}
        />
      </ListItem>
    ));
  };
  const RattingCall = (types) => {
    let confirmedCommands = 0; // Remplacez par le nombre réel de commandes confirmées
    let cancelledCommands = 0; // Remplacez par le nombre réel de commandes annulées
    types.forEach((element) => {
      if (element.idtype_order === 4) {
        confirmedCommands = element.nbrOrder;
      }
      if (element.idtype_order === 0) {
        cancelledCommands = element.nbrOrder;
      }
    });
    // Calculez le pourcentage de commandes confirmées
    const confirmationPercentage =
      (confirmedCommands / (confirmedCommands + cancelledCommands)) * 100;

    // Convertissez le pourcentage en une note sur 5 (car Rating va jusqu'à 5)
    return (confirmationPercentage / 100) * 5;
  };
  return (
    <Card className="card_call-center" elevation={3}>
      <div className="call-center_title">
        <img src={call} width={30} height={30} /> {UserList.username}
      </div>
      <ListItemHtml />
      <div className="rating_calls">
        <Rating
          name="size-large"
          readOnly
          defaultValue={RattingCall(UserList?.types)}
          precision={0.5}
          size="large"
        />
      </div>
    </Card>
  );
};

export default CardCall;
