import { Box, Button, Card, IconButton, Menu, MenuItem } from "@mui/material";
import { HiDotsVertical } from "react-icons/hi";
import {} from "@mui/icons-material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useState } from "react";
import { IoIosTimer } from "react-icons/io";
import CountUp from "react-countup";
const CardAdmin = ({
  color,
  card,
  optionSelected,
  setOptionSelected,
  CountOn,
  type,
}) => {
  // { icon, title, value,red ou green }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e, id) => {
    if (id) {
      const Newoptions = optionSelected.map((option) => {
        return option.id === id ? { option: e, id } : option;
      });
      setOptionSelected(Newoptions);
    }

    setAnchorEl(null);
  };
  const options = ["Last Day", "Last Week", "Last Month", "Last Year"];
  return (
    <Card
      component={Button}
      elevation={1}
      style={{
        textAlign: "left",
        textTransform: "none",
        backgroundImage: `linear-gradient(to right,${color?.[0]},${color?.[1]})`,
      }}
      className="dashboardBox"
    >
      {card.grow ? (
        <span className="chart">
          <TrendingUpIcon />
        </span>
      ) : (
        <span className="chart">
          <TrendingDownIcon />
        </span>
      )}
      <Box display={"flex"} width={"100%"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Box
            component={"h4"}
            color={"#fff"}
            margin={0}
            fontWeight={"600"}
            fontSize={"16px"}
          >
            {card.title}
          </Box>
          <Box
            width={"100%"}
            textAlign={"left"}
            display={"flex"}
            lineHeight={"35px"}
            component={"span"}
            fontSize={"30px"}
            fontWeight={"700"}
            color={"#fff"}
          >
            {CountOn && (
              <CountUp
                start={0}
                end={card.value}
                duration={2}
                delay={0}
                suffix={type ? type + " " : ""}
              />
            )}
          </Box>
        </Box>
        <Box marginLeft={"auto"}>
          <span className="icon">{card.icon}</span>
        </Box>
      </Box>

      <Box display={"flex"} alignItems={"center"} width={"100%"}>
        <Box
          color={"#fff"}
          marginBottom={0}
          marginTop={0}
          fontSize={"14px"}
          component={"h6"}
          fontWeight={"500"}
        >
          {optionSelected.find((selected) => selected.id === card.id)?.option}
        </Box>
        <Box component={"span"} marginLeft={"auto"} className="bottomEl">
          <IconButton onClick={handleClick}>
            <HiDotsVertical className="toggleIcon" />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            open={open}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                // selected={option === "Last Month"}
                onClick={() => handleClose(option, card.id)}
              >
                <IoIosTimer
                  color="#000"
                  fontSize={"17px"}
                  style={{ marginRight: "10px" }}
                />
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
    </Card>
  );
};

export default CardAdmin;
