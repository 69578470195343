import { createContext, useState } from "react";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    isAuth: false,
    idUser: undefined,
    username: undefined,
    phone: undefined,
    email: undefined,
    accessToken: undefined,
    role: undefined,
    permissions: undefined,
    soon_expired: undefined,
  });
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
