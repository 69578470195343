import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  CatchError,
  ToastStatus,
  UpdateShopApiElement,
} from "setup/services";

const AffectationShop = ({
  domaine,
  shops,
  handleClose,
  open,
  branch,
  setDomaines,
  platforme,
}) => {
  const navigate = useNavigate();
  const [shopSelected, setShopSelected] = useState(null);
  const [errorShop, setErorShop] = useState("");
  const sendAuto = () => {
    if (shopSelected === null) {
      setErorShop("Ce champs boutique est requis");
    } else {
    }
  };

  const AffecterMessage = () => {
    toast.success("L'affectation a été bien valider !", {
      ...ToastStatus,
    });
  };

  return (
    <>
      <Dialog maxWidth={"md"} fullWidth open={open} onClose={handleClose}>
        <DialogTitle color={"#01579b"} fontWeight={"600"}>
          Affectation {capitalizeFirstLetter(platforme)} avec{" "}
          {shopSelected !== null ? shopSelected?.name_shop : "..."}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <Autocomplete
              options={shops || []}
              getOptionLabel={(option) => (option ? option.name_shop : "")}
              className="auto-complete"
              isOptionEqualToValue={(option, value) =>
                option.id_shop === value.id_shop
              }
              sx={{ minWidth: "320px" }}
              value={shopSelected}
              onChange={(event, newValue) => {
                setShopSelected(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  error={errorShop === "" ? false : true}
                  helperText={errorShop === "" ? false : errorShop}
                  {...params}
                  label="Boutiques"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleClose}>
            Annuler
          </Button>
          <Button onClick={sendAuto}>Valider</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AffectationShop;
