import { Box, Button, CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
const ButtonLoaded = ({
  success,
  loading,
  handleButtonClick,
  title,
  disabled,
}) => {
  const buttonSx = {
    ...(success && {
      bgcolor: blue[500],
      "&:hover": {
        bgcolor: blue[700],
      },
    }),
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <Box sx={{ m: 1, position: "relative" }}>
        <Fab
          aria-label="save"
          color="primary"
          sx={buttonSx}
          onClick={handleButtonClick}
        >
          {success ? <CheckIcon /> : <SaveIcon />}
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box> */}
      <Box sx={{ m: 1, position: "relative" }}>
        <Button
          variant="contained"
          sx={buttonSx}
          size="small"
          style={{ textTransform: "none" }}
          disabled={!disabled ? loading : !disabled}
          onClick={handleButtonClick}
        >
          {title}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: blue[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ButtonLoaded;
