import React from "react";
import { Box, Breadcrumbs, Link, Paper, Typography } from "@mui/material";
import { Link as LinkReact } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import FolderIcon from "@mui/icons-material/Folder";
import { capitalizeFirstLetter } from "setup/services";
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
const CustomBox = ({ parent, url, title, children, ...props }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{ backgroundColor: "white" }}
      component={Paper}
      elevation={2}
      paddingX={4}
      paddingY={1}
      fontWeight="500"
      marginBottom={2}
      borderRadius={2}
      {...props}
    >
      {/* font-size: 18px !important;
  font-weight: 700;
  color: #403e57 !important; */}
      <Box fontSize={"18px"} fontWeight="600" color="#403e57">
        {title}
      </Box>
      {children}
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
        <LinkReact to={"/"}>
          <Link
            underline="hover"
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <StyledBreadcrumb
              component="a"
              label="Home"
              icon={<HomeIcon fontSize="small" />}
            />
          </Link>
        </LinkReact>

        {parent && (
          <LinkReact to={`/${url}`}>
            <Link underline="hover" color="primary">
              <StyledBreadcrumb
                component="a"
                label={parent}
                icon={<FolderIcon fontSize="small" />}
              />
            </Link>
          </LinkReact>
        )}
        <Typography color="text.primary">
          {" "}
          <StyledBreadcrumb label={title} />
        </Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default CustomBox;
